import * as React from "react"

const Electric = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 98.62 91.84"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#290f40}"}</style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M49.31 81.73c19.75 0 35.81-16.06 35.81-35.81S69.06 10.11 49.31 10.11 13.5 26.17 13.5 45.92s16.06 35.81 35.81 35.81Z"
        style={{
          fill: "#f0f0f0",
        }}
      />
      <path
        className="cls-2"
        d="M49.31 81.73c19.75 0 35.81-16.06 35.81-35.81S69.06 10.11 49.31 10.11 13.5 26.17 13.5 45.92s16.06 35.81 35.81 35.81Zm0-67.85c17.66 0 32.04 14.37 32.04 32.04S66.98 77.96 49.31 77.96 17.27 63.59 17.27 45.92s14.38-32.04 32.04-32.04Z"
      />
      <path
        className="cls-2"
        d="M40.2 65.2c.48 0 .97-.18 1.33-.55l18.22-18.22c.54-.54.7-1.35.41-2.06s-.98-1.16-1.74-1.16H45.93l13.34-13.34c.74-.74.74-1.93 0-2.67s-1.93-.74-2.67 0L40.04 43.76c-.54.54-.7 1.35-.41 2.06.29.7.98 1.16 1.74 1.16h12.49l-15 15a1.883 1.883 0 0 0 1.33 3.22Z"
      />
      <path
        className="cls-2"
        d="M49.35 89.15c-21.41 0-39.23-15.64-42.64-36.1l2.05 2.05c.53.53 1.38.53 1.91 0s.52-1.38 0-1.91l-4.32-4.31a1.348 1.348 0 0 0-2 .11L.3 53.98a1.35 1.35 0 1 0 2.1 1.7l1.68-2.07c3.67 21.68 22.57 38.24 45.27 38.24s42.74-17.57 45.56-40.2h-2.72c-2.81 21.14-20.95 37.5-42.85 37.5ZM98.07 36.09c-.6-.44-1.45-.3-1.88.3l-1.52 2.1C91.1 16.69 72.14 0 49.35 0S6.61 17.57 3.79 40.2h2.72C9.31 19.06 27.45 2.7 49.35 2.7s39.21 15.63 42.63 36.08l-2.18-1.95a1.345 1.345 0 1 0-1.8 2l4.79 4.3c.25.22.57.34.9.34h.14c.38-.04.72-.24.95-.55l3.58-4.94c.44-.6.3-1.45-.3-1.88Z"
      />
    </g>
  </svg>
)

export default Electric
