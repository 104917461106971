import * as React from "react"

const Shield = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71.77 83.23"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#290f40}"}</style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M70.09 12.47c-.12-.53-.5-.95-1.02-1.13L36.58.16c-.12-.04-.25-.07-.37-.08a1.55 1.55 0 0 0-1.02 0L2.69 11.27c-.51.18-.89.6-1.02 1.13-.13.57-3.18 14.1-.72 29.64 1.46 9.2 4.56 17.16 9.23 23.66 5.85 8.16 14.16 14.01 24.69 17.38.16.05.32.08.48.08.15 0 .29-.02.43-.06.2.09.42.14.64.14.16 0 .32-.02.48-.08 10.53-3.37 18.84-9.22 24.69-17.38 4.66-6.5 7.77-14.46 9.23-23.66 2.47-15.54-.59-29.08-.72-29.64Z"
        style={{
          fill: "#f0f0f0",
        }}
      />
      <path
        className="cls-2"
        d="M70.09 12.47c-.12-.53-.5-.95-1.02-1.13L36.58.16c-.12-.04-.25-.07-.37-.08a1.55 1.55 0 0 0-1.02 0L2.69 11.27c-.51.18-.89.6-1.02 1.13-.13.57-3.18 14.1-.72 29.64 1.46 9.2 4.56 17.16 9.23 23.66 5.85 8.16 14.16 14.01 24.69 17.38.16.05.32.08.48.08.15 0 .29-.02.43-.06.2.09.42.14.64.14.16 0 .32-.02.48-.08 10.53-3.37 18.84-9.22 24.69-17.38 4.66-6.5 7.77-14.46 9.23-23.66 2.47-15.54-.59-29.08-.72-29.64Zm-2.38 29.24c-1.39 8.69-4.32 16.19-8.7 22.28C53.57 71.56 45.82 77 35.99 80.16c-.05-.02-.11-.05-.16-.06-9.86-3.16-17.62-8.6-23.07-16.18-4.38-6.09-7.3-13.59-8.7-22.28-2.05-12.82-.12-24.53.49-27.7L35.77 3.19l31.45 10.82c.61 3.17 2.54 14.88.49 27.7Z"
      />
      <path
        className="cls-2"
        d="M48.66 31.57 31.89 48.34l-8.77-8.77a1.563 1.563 0 0 0-2.21 2.21l9.88 9.88c.31.31.71.46 1.11.46s.8-.15 1.11-.46l17.88-17.88a1.563 1.563 0 0 0-2.21-2.21Z"
      />
    </g>
  </svg>
)

export default Shield
