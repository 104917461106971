import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 78.44 78.44"
    {...props}
  >
    <defs>
      <style>{".cls-2{fill:#290f40}"}</style>
    </defs>
    <g id="Layer_1-2">
      <path
        d="M39.22 0C17.59 0 0 17.59 0 39.22s17.59 39.22 39.22 39.22 39.22-17.59 39.22-39.22S60.84 0 39.22 0Z"
        style={{
          fill: "#f0f0f0",
        }}
      />
      <path
        className="cls-2"
        d="M39.22 0C17.59 0 0 17.59 0 39.22s17.59 39.22 39.22 39.22 39.22-17.59 39.22-39.22S60.84 0 39.22 0Zm0 75.52c-20.02 0-36.3-16.29-36.3-36.3s16.28-36.3 36.3-36.3 36.3 16.29 36.3 36.3-16.29 36.3-36.3 36.3Z"
      />
      <path
        className="cls-2"
        d="M23.35 20.15h4.93l-2.97-2.97h-6.99v6.99l2.97 2.97v-4.93l10.34 10.35c.28.28.66.43 1.03.43s.75-.14 1.03-.43c.57-.57.57-1.49 0-2.06L23.34 20.15ZM53.12 17.18l-2.97 2.97h4.93L44.73 30.49c-.57.57-.57 1.49 0 2.06.28.28.66.43 1.03.43s.75-.14 1.03-.43L57.14 22.2v4.93l2.97-2.97v-6.99h-6.99ZM31.63 45.88 21.28 56.23V51.3l-2.97 2.97v6.99h6.99l2.97-2.97h-4.93l10.35-10.34c.57-.57.57-1.49 0-2.06-.57-.57-1.49-.57-2.06 0ZM57.15 56.23 46.8 45.88c-.57-.57-1.49-.57-2.06 0-.57.57-.57 1.49 0 2.06l10.35 10.35h-4.93l2.97 2.97h6.99v-6.99l-2.97-2.97v4.93Z"
      />
    </g>
  </svg>
)

export default SvgComponent
