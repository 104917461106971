import * as React from "react"

const Leaf = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.45 90.71" {...props}>
    <path
      d="M48.33 19.21c-3.24-5.09-6.83-9.86-10.1-13.43C32.92 0 30.87 0 30.19 0s-2.65 0-7.78 5.81c-3.16 3.58-6.62 8.35-9.74 13.44C7.94 26.96 0 41.89 0 55.27c0 7.14 2.29 12.76 6.82 16.72 4.88 4.27 12.35 6.52 22.21 6.68v10.35c0 .93.76 1.69 1.69 1.69s1.69-.76 1.69-1.69V78.65c9.7-.29 17.08-2.53 21.96-6.65 4.7-3.97 7.08-9.61 7.08-16.77 0-13.4-8.22-28.32-13.11-36.02Z"
      style={{
        fill: "#f0f0f0",
      }}
    />
    <path
      d="M48.33 19.21c-3.24-5.09-6.83-9.86-10.1-13.43C32.92 0 30.87 0 30.19 0s-2.65 0-7.78 5.81c-3.16 3.58-6.62 8.35-9.74 13.44C7.94 26.96 0 41.89 0 55.27c0 7.14 2.29 12.76 6.82 16.72 4.88 4.27 12.35 6.52 22.21 6.68v10.35c0 .93.76 1.69 1.69 1.69s1.69-.76 1.69-1.69V78.65c9.7-.29 17.08-2.53 21.96-6.65 4.7-3.97 7.08-9.61 7.08-16.77 0-13.4-8.22-28.32-13.11-36.02Zm3.2 50.73c-4.28 3.27-10.7 5.06-19.12 5.32v-6.01L46.6 55.06a1.69 1.69 0 1 0-2.39-2.39L32.42 64.46V53.07l8.54-8.54a1.69 1.69 0 1 0-2.39-2.39l-6.15 6.15V34.36c0-.93-.76-1.69-1.69-1.69s-1.69.76-1.69 1.69v13.93l-6.15-6.15a1.69 1.69 0 1 0-2.39 2.39l8.54 8.54v11.39L17.25 52.67a1.69 1.69 0 1 0-2.39 2.39l14.19 14.19v6.04c-8.41-.14-14.84-1.87-19.11-5.13-3.81-2.91-5.97-7.13-6.44-12.55-1.06-12.29 6.47-27.14 11.05-34.9C21.54 10.84 28.5 3.78 30.2 3.39c1.45.28 8.19 6.17 16.06 18.89 8.05 13.01 12.33 25.65 11.75 34.68-.36 5.61-2.54 9.98-6.48 12.99Z"
      style={{
        fill: "#290f40",
      }}
    />
  </svg>
)

export default Leaf;