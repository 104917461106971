import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby";
import Seo from "../components/organisms/Seo/Seo";
import Layout from "../components/organisms/Layout/Layout";
import Breadcrumbs from "../components/atoms/Breadcrumbs/Breadcrumbs";
import PageBannerPolicy from "../components/atoms/PageBannerPolicy/PageBannerPolicy";
import PageLayout from "../components/atoms/Contentful/ContentfulPageLayout";
import * as styles from './why-lavo.module.css';
import Container from "../components/atoms/Container/Container";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
// import { useWindowDimensions } from "../helpers/general";
import Shield from "../assets/svg/TempIcons/shield";
import Leaf from "../assets/svg/TempIcons/leaf";
import Arrows from "../assets/svg/TempIcons/arrows";
import Electric from "../assets/svg/TempIcons/electric";

const WhyLavoContentful = ({data}) => {
  const {pageName, pageTitle, metaTitle} = data.whylavoPage;
  const page = data.whylavoPage;
  const firstBlock = (page.blocks && page.blocks[0]) || false;

  const contentfulSections = (firstBlock && firstBlock.blocks) || false;
  const blockAlignment = (firstBlock && firstBlock.alignment.alignment) || false;
  const blockWidth = (firstBlock.blockWidth && firstBlock.blockWidth.blockWidth) || false;
  const textColour = firstBlock.textColour || false;
  // const blockPaddingTopBottom = (firstBlock.paddingTopBottom && firstBlock.paddingTopBottom.paddingTopBottom.paddingTopBottom) || false;
  const layoutStyle = (page.logoStyle && page.logoStyle.logoStyle) || false;
  const genClass = pageName ? 'contentful-page page-' + pageName.trim().replace('.', '').replace(/[^A-Z0-9]+/ig, "-").toLowerCase() : '';
  const dataHelmet = { pageName, pageTitle, metaTitle };
  const secondBlock = (page.blocks && page.blocks[1]) || false;
  const secondBlockContents = secondBlock.blocks;
  const thirdBlock = (page.blocks && page.blocks[2]) || false;
  const thirdBlockcontentfulSections = (thirdBlock && thirdBlock.blocks) || false;
  const secondBlockIcons = secondBlockContents[1].blocks;
  const buttonContainerRef = useRef(null);
  const buttonRef = useRef();
  const seoTitle = page.seoTitle || page.pageName;
  const handleMouseMove = (event) => {
    const containerRect = buttonContainerRef.current.getBoundingClientRect();
    // const offsetX = containerRect.width - 75 >= event.offsetX ? event.offsetX > 0 ? event.offsetX : 50 : event.offsetX ;
    // const offsetY = containerRect.height - 75 >= event.offsetY ? event.offsetY > 0 ? event.offsetY : 50 : event.offsetY;
    let offsetX = event.clientX - containerRect.left;
    let offsetY = event.clientY - containerRect.top;
    if (event.clientX > containerRect.right - 75) {
      offsetX = containerRect.right - event.offsetX + 200;
    }
    if (event.clientY > containerRect.bottom - 75) {
      offsetY = event.clientY + containerRect.bottom;
    }
    // console.log(event.clientY, containerRect.bottom, event.offsetY);
    buttonRef.current.style.top = offsetY + 'px';
    buttonRef.current.style.left = offsetX + 'px';
  }

  useEffect(() => {
    const containerElement = buttonContainerRef.current;

    containerElement.addEventListener('mouseenter', (e) => {
      containerElement.addEventListener('mousemove', handleMouseMove);
      
    });
  }, []);

  // const handleIconClick = () => {
  //   console.log('test');
  // }
  // const { width } = useWindowDimensions();
  // const isMobile = width < 768;
  // console.log(firstBlock)
  const [activeIcon, setActiveIcon] = useState(null);
  return (
    <ParallaxProvider>
      <Layout varHelmet={dataHelmet} genClass={genClass} layoutStyle={layoutStyle} className={styles.container}>
        <Seo title={seoTitle} description={page.metaDescription} image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}/>
        <PageBannerPolicy
          title={pageTitle}
          description={metaTitle}
        />
        <Container size="medium">
          <Breadcrumbs crumbs={[{link: '/', label: 'Home'}, {label: pageName}]}/>
          <h3 className={styles.heading3} style={{marginTop: 0}}>
            {contentfulSections[0].heading}
          </h3>
          <div className={styles.mainContentWrapper} dangerouslySetInnerHTML={{
                __html: contentfulSections[0].content.childMarkdownRemark.html
              }} style={{paddingTop: 0}}>
            
          </div>
        </Container>
        <div className={styles.whyLavoFullWidthImageWrapper}>
          <div className={styles.whyLavoHeroImage}>
            <img src={contentfulSections[1].backgroundImage.desktopImage.file.url} alt=''/>
          </div>
        </div>
        <Container size="medium">
          <div className={styles.mainContentWrapper}>
            <h3 className={styles.heading3}>
              {contentfulSections[2].heading}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: contentfulSections[2].content.childMarkdownRemark.html
              }}></div>
          </div>
        </Container>

        <div className={styles.whyLavoFullWidthParallaxWrapper} style={{backgroundColor: '#'+contentfulSections[4].backgroundColour}}>
          <div className={styles.whyLavoHeroParallaxImage}>
            <img src={contentfulSections[3].desktopImage.file.url} alt=''/>
          </div>

          <Parallax speed={20}>
            <div data-components
                 data-index={4}
                 data-alignment={blockAlignment}
                 data-text-color={textColour}
                 style={{width: blockWidth}}
                 data-last={false}
                 className={styles.whyLavoContainerDoubleWithArtwork}
            >
                <PageLayout
                  pageName={pageName}
                  section={contentfulSections[4]}
                  sectionIndex={4}
                />
            </div>
          </Parallax>
        </div>
        <div className={styles.whyLavoContainer}>
          <Container size={'medium'}>
            <div className={styles.whyLavoDoubleCard}>
              <div data-components
                   data-index={5}
                   data-alignment={blockAlignment}
                   data-text-color={'261A36'}
                   style={{width: blockWidth}}
                   data-last={false}
              >
                <PageLayout
                  pageName={pageName}
                  section={contentfulSections[5]}
                  sectionIndex={5}
                />
              </div>

            </div>
          </Container>
        </div>

        <div className={styles.whyLavoFullWidthContentWrapper} style={{backgroundColor: '#'+contentfulSections[6].backgroundColour}}>
          <Container size="medium">
            <div className={styles.mainContentWrapper}>
              <h3 className={styles.heading3} les="les" style={{color: '#'+contentfulSections[6].headingColor}}>
                {contentfulSections[6].heading}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: contentfulSections[6].content.childMarkdownRemark.html
                }}></div>
            </div>
          </Container>
        </div>
        <Container size="medium" className={styles.whyLavoContainer}>
          <div className={styles.mainContentWrapper}>
            <h3 className={styles.heading3}>
              {contentfulSections[7].heading}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: contentfulSections[7].content.childMarkdownRemark.html
              }}></div>
          </div>
        </Container>
        <div className={styles.whyLavoFullWidthImageWrapper}>
          <div className={styles.whyLavoHeroImage}>
            <img src={contentfulSections[8].desktopImage.file.url} alt=''/>
          </div>
        </div>
        <div className={styles.whyLavoLdes}>
          <Container size={'medium'}>
            <div className={styles.whyLavoLdesInnerWrapper}>
              <div className={styles.ldesHeading}>
                <h3>{secondBlockContents[0].name}</h3>
                <h3>{secondBlockContents[0].heading}</h3>
                <div dangerouslySetInnerHTML={{__html: secondBlockContents[0].content.childMarkdownRemark.html}}></div>
              </div>
              <div className={styles.ldesIcons} ref={buttonContainerRef}>
                {
                  secondBlockIcons.map((icon, index) => {
                    if (!icon.icon) {
                      return false;
                    }
                    const iconContent = JSON.parse(icon.content.raw);
                    return (
                      <div key={index} className={`${styles.ldesIconWrapper} icon-wrapper`} role="presentation" onClick={() => setActiveIcon(index)}>
                        {icon.iconText === 'shield' && <Shield width={135} height={125} />}
                        {icon.iconText === 'leaf' && <Leaf  width={135} height={125} />}
                        {icon.iconText === 'electric' && <Electric  width={135} height={125} />}
                        {icon.iconText === 'arrows' && <Arrows  width={135} height={125} />}
                        {/*<object data={icon.icon.file.url} type={"image/svg+xml"} style={{pointerEvents: `${isMobile ? 'none' : 'fill'}`}} ></object>*/}
                        <div className={`${styles.hoverContent} ${index === activeIcon ? styles.showHoverContent : ''}`}>
                          <h3>{icon.heading}</h3>
                          <p>
                            {iconContent.content[0].content[0].value}
                          </p>
                        </div>
                      </div>
                    )
                  }).filter(icon => icon)
                }
                <div data-btn className={styles.btnHover} ref={buttonRef}>
                <div>Hover</div>
                </div>
              </div>
             
              <div className={styles.ldesContent}>
                <div dangerouslySetInnerHTML={{__html: secondBlockContents[2].content.childMarkdownRemark.html}}></div>
              </div>
            </div>

          </Container>
        </div>
        {/* 3rd block */}
        <div className={styles.whyLavoContainer}>
          <Container size={'medium'}>
            <div className={`${styles.whyLavoDoubleCard} ${styles.whyLavoNetZero}`}>
              <div data-components
                   data-index={5}
                   data-alignment={blockAlignment}
                   data-text-color={'261A36'}
                   style={{width: blockWidth}}
                   data-last={false}
              >
                <PageLayout
                  pageName={pageName}
                  section={thirdBlockcontentfulSections[0]}
                  sectionIndex={5}
                />
              </div>

            </div>
          </Container>
        </div>
        <div style={{backgroundColor: '#'+ thirdBlockcontentfulSections[1].backgroundColour}}>
          <div className={styles.whyLavoContainer}>
            <Container size={'medium'}>
              <div className={`${styles.whyLavoDoubleCard} ${styles.whyLavoUnlock}`}>
                <div data-components
                     data-index={5}
                     data-alignment={blockAlignment}
                     data-text-color={'FFFFFF'}
                     style={{width: blockWidth}}
                     data-last={false}
                >
                  <PageLayout
                    pageName={pageName}
                    section={thirdBlockcontentfulSections[1]}
                    sectionIndex={5}
                  />
                </div>

              </div>
            </Container>
          </div>
        </div>
        <div className={styles.whyLavoFullWidthImageWrapper}>
          <div className={styles.whyLavoHeroImage}>
            <img src={thirdBlockcontentfulSections[2].desktopImage.file.url} alt=''/>
          </div>
        </div>
      </Layout>
    </ParallaxProvider>

  );
}

export default WhyLavoContentful;
export const query = graphql`
query ContentfulPageWhyLavo {
    whylavoPage: contentfulPage(pageName: {eq: "Why LAVO"}) {
        pageName
        seoTitle
    pageTitle
    metaCanonicalLink
    metaDescription
    metaTitle
    metaPreviewImage{
      file {
        url
      }
    }
    breadcrumbs
    logoStyle {
      logoStyle
    }
    blocks {
      textColour
      backgroundColour
      alignment {
        alignment
      }
      blockWidth {
        blockWidth
      }
      paddingTopBottom {
        paddingTopBottom {
          paddingTopBottom
        }
      }
      blocks {
        ... on ContentfulBlocks {
          blocks {
            ... on ContentfulHoverBlock {
              icon {
                file {
                  url
                  fileName
                }
              }
              content {
                raw
              }
              heading
              iconText
            }
          }
        }
        ... on ContentfulBlockImage {
          id
          desktopImage {
            file {
              fileName
              url
            }
          }
          mobileImage {
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulBlockButtons {
          name
          internal {
            type
          }
          buttons {
            name
            buttonText
            deepLink
            url
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
        }
        ... on ContentfulBlockSlides {
          name
          classes
          internal {
            type
          }
          blocks {
            name
            blockWidth {
              blockWidth
            }
            alignment {
              alignment
            }
          }
          backgroundColour
          slide {
            name
            heading
            subText {
              childMarkdownRemark {
                html
              }
            }
            image {
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
              imageMaxWidth
            }
            vimeoId
            backgroundColor
            button {
              buttonText
              deepLink
              name
              url
            }
            coloredSubText {
              textColor
              subText
            }
          }
          buttonName
          buttonUrl
          heading
          slideStyle {
            slideStyle
          }
          slideToShow {
            slideToShow
          }
          textColour {
            textColour {
              textColour
            }
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          textAlignment {
            textAlignment
          }
        }
        ... on ContentfulBlockSingleCard {
          name
          internal {
            type
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          backgroundColour
          vimeoId
          content {
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            heading
            headingSize {
              headingSize
            }
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textAlignment {
              textAlignment
            }
            textColour
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on ContentfulBlockDoubleCards {
          name
          internal {
            type
          }
          title
          backgroundColour
          textColour {
            textColour {
              textColour
            }
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          blocks {
            alignment {
              alignment
            }
            blockWidth {
              blockWidth
            }
            backgroundColour
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          blockAlignment {
            blockAlignment
          }
          mobileRevert
          contentLeft {
            heading
            headingColor
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
          contentRight {
            heading
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
        }
        ... on ContentfulBlockContent {
          name
          backgroundColour
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          blockAlignment {
            blockAlignment
          }
          buttonName
          buttonUrl
          buttonStyle {
            buttonStyle
          }
          heading
          headingColor
          headingSize {
            headingSize
          }
          imagePosition {
            imagePosition
          }
          imageHoverAnimation {
            imageHoverAnimation
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          textAlignment {
            textAlignment
          }
          imagePosition {
            imagePosition
          }
          internal {
            type
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulBlockAniParallaxScrolling {
          name
          heading
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          internal {
            type
          }
          backgroundColour
          backgroundImage {
            imageMaxWidth
            altText
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          textColour {
            textColour {
              textColour
            }
          }
          style {
            style
          }
          stickyContent {
            headingSize {
              headingSize
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            imagePosition {
              imagePosition
            }
            name
            textAlignment {
              textAlignment
            }
            textColour
            heading
            className
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
          scrollingContents {
            headingSize {
              headingSize
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            imagePosition {
              imagePosition
            }
            name
            textAlignment {
              textAlignment
            }
            textColour
            heading
            headingColor
            className
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
        }
        ... on ContentfulBlockTiles {
          name
          internal {
            type
          }
          blockAlignment {
            blockAlignment
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          blockStyle {
            blockStyle
          }
          backgroundColour
          textColour {
            textColour {
              textColour
            }
          }
          title
          tile {
            ... on ContentfulBlockContent {
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              heading
              headingSize {
                headingSize
              }
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              textAlignment {
                textAlignment
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulBlockDoubleCards {
              name
              internal {
                type
              }
              backgroundColour
              textColour {
                textColour {
                  textColour
                }
              }
              blocks {
                alignment {
                  alignment
                }
                blockWidth {
                  blockWidth
                }
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              mobileRevert
              contentLeft {
                heading
                childContentfulBlockContentContentTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                headingSize {
                  headingSize
                }
                textAlignment {
                  textAlignment
                }
                textColour
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
              }
              contentRight {
                heading
                childContentfulBlockContentContentTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                headingSize {
                  headingSize
                }
                textAlignment {
                  textAlignment
                }
                textColour
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
              }
            }
          }
        }
      }
    }
    }
}
`