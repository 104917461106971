// extracted by mini-css-extract-plugin
export var container = "why-lavo-module--container--LqovQ";
export var mainContentWrapper = "why-lavo-module--mainContentWrapper--DRlhV";
export var lifeUpdatesHeading = "why-lavo-module--lifeUpdatesHeading--alTbh";
export var heading3 = "why-lavo-module--heading3---KBAe";
export var whyLavoHeroImage = "why-lavo-module--whyLavoHeroImage--D0EMS";
export var whyLavoFullWidthImageWrapper = "why-lavo-module--whyLavoFullWidthImageWrapper--kLbj8";
export var whyLavoFullWidthParallaxWrapper = "why-lavo-module--whyLavoFullWidthParallaxWrapper--IZX29";
export var parallaxContainer = "why-lavo-module--parallaxContainer--bTtze";
export var whyLavoHeroParallaxImage = "why-lavo-module--whyLavoHeroParallaxImage--hgK8l";
export var textOnlyContent = "why-lavo-module--textOnlyContent--1ee5P";
export var whyLavo = "why-lavo-module--whyLavo--f97jx";
export var whyLavoDoubleCard = "why-lavo-module--whyLavoDoubleCard--mSJym";
export var whyLavoContainerDoubleWithArtwork = "why-lavo-module--whyLavoContainerDoubleWithArtwork--RVUIK";
export var whyLavoNetZero = "why-lavo-module--whyLavoNetZero--6T+j6";
export var whyLavoUnlock = "why-lavo-module--whyLavoUnlock--f1Cgk";
export var whyLavoestimonials = "why-lavo-module--whyLavoestimonials--4f209";
export var whyLavoFullWidthContentWrapper = "why-lavo-module--whyLavoFullWidthContentWrapper--hikIx";
export var whyLavoLdes = "why-lavo-module--whyLavoLdes--oCn3e";
export var whyLavoLdesInnerWrapper = "why-lavo-module--whyLavoLdesInnerWrapper--jPQmE";
export var ldesHeading = "why-lavo-module--ldesHeading--lzY68";
export var ldesIcons = "why-lavo-module--ldesIcons---2dJ+";
export var ldesIconWrapper = "why-lavo-module--ldesIconWrapper--6pblb";
export var hoverContent = "why-lavo-module--hoverContent--4fJEz";
export var btnHover = "why-lavo-module--btnHover--d+CWT";
export var cls2 = "why-lavo-module--cls-2--sfj6F";
export var productCards = "why-lavo-module--productCards--7VpF4";
export var productContent = "why-lavo-module--productContent--t-i8t";
export var productTitle = "why-lavo-module--productTitle--ow3fD";
export var showHoverContent = "why-lavo-module--showHoverContent--SGQ9R";
export var whyLavoContainer = "why-lavo-module--whyLavoContainer--deXKu";
export var ldesContent = "why-lavo-module--ldesContent--9HL8c";